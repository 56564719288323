import editDiv from "@/view/exam/activeEdit.vue";
import { getActivePage } from "@/api/api";
export default {
  name: "activeList",
  components: {
    editDiv
  },
  data() {
    return {
      tableData: [],
      dataInfo: [],
      Info: null,
      dialogFormVisible: false,
      pageInfo: {
        pageSize: 10,
        name: '',
        current: 1,
        total: 0
      },
      exportIds: ''
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    reset() {
      this.pageInfo.name = '';
    },
    search() {
      this.pageInfo.pageSize = 10;
      this.pageInfo.current = 1;
      this.getList();
    },
    getList() {
      getActivePage(this.pageInfo).then(res => {
        this.pageInfo.total = parseInt(res.data.total);
        this.tableData = res.data.records;
      });
    },
    showEdit(row) {
      this.dialogFormVisible = true;
      this.Info = row;
      let data = '';
      if (row) {
        data = JSON.stringify(this.Info);
      }
      this.$refs.editDiv.getInfo(data); //调用子组件方法
    },
    showRenew(id) {
      this.dialogFormVisible1 = true;
      this.$refs.renewDiv.getInfo(id); //调用子组件方法
    },
    closeDiv() {
      this.dialogFormVisible = false;
    },
    handleSizeChange(e) {
      //修改每页显示条数
      this.pageInfo.pageSize = e;
      this.pageInfo.current = 1;
      this.getList();
    },
    handleCurrentChange(e) {
      this.pageInfo.current = e;
      this.getList();
    }
  }
};